import { tailwind as baseStyles } from "@theme-ui/presets"

export default {
  ...baseStyles,
  styles: {
    ...baseStyles.styles,
  },
  colors: {
    ...baseStyles.colors,
    primary: baseStyles.colors.green[7],
    primaryHover: baseStyles.colors.green[5],
    secondary: baseStyles.colors.orange[7],
  },
}
