import React, { createContext, useContext, useState } from "react"
import { persistConsentsInCookie, readConsentsFromCookie } from "./cookie"
const consents = {
  add: (...consentsToAdd) => {},
  clear: () => {},
  get: () => [],
  remove: (...consentsToRemove) => {},
}
/**
 * Cookie consents context
 */
export const CookieConsentsContext = createContext(consents)

/**
 * Provider to provide cookie consent controls via the `useCookieConsents()` hooks
 * @param {*} props Component props
 * @returns {*} React component
 */
export const CookieConsentsProvider = ({
  children,
  cookieName,
  expiryInDays,
}) => {
  const [currentConsents, setCurrentConsents] = useState(
    readConsentsFromCookie(cookieName)
  )

  const updateConsents = newConsents => {
    persistConsentsInCookie(cookieName, newConsents, expiryInDays)
    setCurrentConsents(newConsents)
  }

  const consents = {
    add: (...consentsToAdd) => {
      updateConsents(
        Array.from(new Set([...currentConsents, ...consentsToAdd]))
      )
    },
    clear: () => {
      updateConsents([])
    },
    get: () => currentConsents,
    remove: (...consentsToRemove) => {
      updateConsents(currentConsents.filter(c => !consentsToRemove.includes(c)))
    },
  }

  return (
    <CookieConsentsContext.Provider value={consents}>
      {children}
    </CookieConsentsContext.Provider>
  )
}

/**
 * React hook to provide a `Consents` object used to update cookie consents
 * @returns {*} `Consents` object
 */
export const useCookieConsents = () => {
  return useContext(CookieConsentsContext)
}
